
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import * as Sentry from "@sentry/nextjs";
import Error from "next/error";
const CustomErrorComponent = props => {
  return <Error statusCode={props.statusCode} data-sentry-element="Error" data-sentry-component="CustomErrorComponent" data-sentry-source-file="_error.jsx" />;
};
CustomErrorComponent.getInitialProps = async contextData => {
  // In case this is running in a serverless function, await this in order to give Sentry
  // time to send the error before the lambda exits
  await Sentry.captureUnderscoreErrorException(contextData);

  // This will contain the status code of the response
  return Error.getInitialProps(contextData);
};
const __Page_Next_Translate__ = CustomErrorComponent;

    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  